import React from "react"
import LayoutLogged from "../../components/layout/logged"
import Container from '@material-ui/core/Container';
import AppDataGrid from "../../components/data/app-data-grid";
import { ifNotHaveAccessRedirect } from "../../services/auth";

export default function ConfigOption() {
  if (ifNotHaveAccessRedirect('configuration.write')) return <></>;
  
  return (
    <LayoutLogged title="Opções">
      <Container >
        <AppDataGrid
          api='/option'
          viewLink='/config/option-manager'
          columns={[
            { field: 'name', type: 'string', headerName: 'Nome', width: 500 },
          ]}
          showFilterActive
          showFilterIcon
          showButtonNew={false}
        />
      </Container>
    </LayoutLogged>
  );
}